import { useTypedDispatch, useTypedSelector } from '@stores/index';
import {
	Space,
	Pagination,
	message,
	Collapse,
	Empty,
	Spin,
	Badge,
	Menu,
	Dropdown,
	Tag,
	Button,
} from 'antd';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import {
	ISession,
	IUserWithSession,
	IOnchangeStatus,
	IstatusIcon,
} from '@stores/interfaces';
import { StatusNormalized, limitPagination } from '@stores/constants';
import { useTranslation } from 'react-i18next';
import {
	getEvaluationOutOfParams,
	updateEvaluationSessionStatus,
} from '@stores/outOfParams';
import Avatar from '@atoms/Avatar/Avatar';
import { getUserById, saveState, selectUser } from '@stores/contacts/contacts';
import { useEffect, useState } from 'react';
import { setActiveTab, setStateId } from '@stores/aiAssistant/aiAssistant';
import { router } from '@routers/routers';
import { getStats } from '@stores/adminPatient';

const statusIcons: IstatusIcon = {
	outOfParams: <Badge color="black" />,
	pendingReview: <Badge color="#dabe08" />,
	reviewed: <Badge color="green" />,
	escalationRequired: <Badge color="red" />,
	followUpRequired: <Badge color="orange" />,
};

function Evaluation() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const savedState = useTypedSelector(state => state.contacts.main.ptDashboard);
	const { users, pagination } = useTypedSelector(
		state => state.outOfParams.evaluation,
	);
	const { Panel } = Collapse;
	const [loading, setLoading] = useState(false)
	const [hoveredSession, setHoveredSession] = useState<string | null>(null);

	useEffect(() => {
		onChange()
	}, [])
	
	const onChangeStatus = async (params: IOnchangeStatus) => {
		const { sessionId, status } = params;
		message.loading({ content: 'Saving...', key: sessionId });
		await dispatch(
			updateEvaluationSessionStatus({ sessionId, body: { status } }),
		);
		await dispatch(getStats());
		message.success({
			content: t('Admin.data.pendingReview.submitStatusChanged'),
			key: sessionId,
			duration: 3,
		});
	};

	const expandedRowRender = (record: IUserWithSession) => {
		const { evaluationSession, id } = record;
		const menu = (session: ISession) => (
			<Menu
				onClick={e => {
					e.domEvent.stopPropagation();
					onChangeStatus({ sessionId: session.id, status: e.key });
				}}>
				{Object.entries(StatusNormalized)
					.filter(([key]) => key !== session?.status)
					.map(([value, label]) => (
						<Menu.Item key={value}>
							<Space>
								<span className="dropdown-badge">{statusIcons[value]}</span>
								<span>{label}</span>
							</Space>
						</Menu.Item>
					))}
			</Menu>
		);

		return (
			<div className="expanded-row-main-div" onClick={e => e.stopPropagation()}>
				{evaluationSession?.map((session: ISession) => (
					<div
						key={session.id}
						onClick={async () => {
							dispatch(
								saveState({
									activeTab: 'Evaluation',
									userId: id,
								}),
							);
							navigate(`/${id}${router.AIASSISTANT_LIST_EVALUATION}`);
							dispatch(setActiveTab('listEvaluation'));
							await dispatch(getUserById(id));
							dispatch(setStateId(session.id));
						}}
						className="expand-sub-div btn-check"
						onMouseEnter={() => setHoveredSession(session.id)}
						onMouseLeave={() => setHoveredSession(null)}>
						<div className="date-div">
							<span className="date-div-sub-container">
								<Badge color="black" />
								<span className="session-date">
									{format(new Date(session?.createdAt), 'PPpp')}
								</span>
							</span>
							{hoveredSession === session.id && (
								<Space>
									<Dropdown overlay={menu(session)} trigger={['hover']}>
										<Button
											type="primary"
											shape="round"
											onClick={e => e.stopPropagation()}>
											{t('Admin.data.pendingReview.rehab.changeStatus')}
										</Button>
									</Dropdown>
								</Space>
							)}
						</div>
					</div>
				))}
			</div>
		);
	};

	const onChange = async (page = 1) => {
		if (page) {
			const paginate = {
				...pagination,
				nextPage: page,
			};
			setLoading(users && true);
			await dispatch(getEvaluationOutOfParams(paginate));
			setLoading(false);
		}
	};

	if (loading)
		return (
			<div style={{height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
				<Spin
					tip="Loading"
					size="large"
				/>
			</div>
		);

	return (
		<>
			<div className="card-container admin-total-patient-card-container">
				<div className="admin-total-patient-card-subcontainer">
					{users.length > 0 ? (
						<>
							{' '}
							{users.map((user: IUserWithSession) => {
								return (
									<Badge.Ribbon
										text={
											<span>
												{user.evaluationSession?.length}
												{user.evaluationSession?.length === 1
													? ' Session'
													: ' Sessions'}
											</span>
										}
										color="var(--session-bg-color)">
										<Collapse
											defaultActiveKey={savedState?.userId}
											bordered={false}
											className="pt-panel collapse-form">
											<Panel
												key={user.id}
												className="header-panel collapse-header-form"
												header={
													<div className="header-first-div">
														<div className="header-primary-div">
															<Avatar user={user} />
															<div className="header-first-subdiv">
																<b style={{ textTransform: 'none' }}>
																	{user.profile.fullName
																		? user.profile.fullName
																		: `${user.profile.firstName} ${user.profile.lastName}`}
																</b>
																<br />
																<span style={{ textTransform: 'none' }}>
																	{user.profile.email}
																</span>
															</div>
														</div>
													</div>
												}>
												{expandedRowRender(user)}
											</Panel>
										</Collapse>
									</Badge.Ribbon>
								);
							})}
						</>
					) : users.length === 0 ? (
						<div className="empty-conatiner-css">
							<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
						</div>
					) : (
						<div className="empty-conatiner-css">
							<Spin tip="Loading" size="large" />
						</div>
					)}
				</div>
			</div>
			{users && users.length > 0 && (
				<Pagination
					current={pagination.currentPage}
					defaultPageSize={limitPagination}
					total={pagination.totalCount}
					onChange={onChange}
				/>
			)}
		</>
	);
}

export default Evaluation;
