import TextAnimation from '@atoms/TextAnimation/TextAnimation';
import { ICustomRomExercise } from '@stores/interfaces';
import { TTransitions } from '@stores/rom/main';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useRef, useState } from 'react';
import { PiArrowDownRightBold, PiArrowUpLeftBold } from 'react-icons/pi';

interface IInstructionalVideoProps {
	onNextTransition: () => void;
	onTogglePauseVideo: () => void;
	transition: TTransitions | null;
	currentExercise: ICustomRomExercise;
	isVideoPause: boolean;
	isSwitchMode: boolean;
	isFullscreen: boolean;
	isRepeat: boolean;
}

function InstructionalVideo(props: IInstructionalVideoProps) {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [open, setOpen] = useState(false);
	const [isFull, setFull] = useState(true);
	const [showVideo, setShowVideo] = useState(false);

	const {
		onNextTransition,
		onTogglePauseVideo,
		currentExercise,
		isSwitchMode,
		isFullscreen,
		isRepeat,
		isVideoPause,
		transition,
	} = props;

	// useEffect(() => {
	// 	if (transition?.value == ETransitions.CALIBRATION) {
	// 		setFull(false)
	// 	}
	// }, [transition])

	useEffect(() => {
		if (currentExercise) {
			setOpen(true);
			setFull(true);
			setShowVideo(false);
			const timer = setTimeout(() => {
				setShowVideo(true);
			}, 4500);

			return () => clearTimeout(timer);
		}
	}, [currentExercise]);

	useEffect(() => {
		isVideoPause ? videoRef.current?.pause() : videoRef.current?.play();
	}, [isVideoPause]);

	const onToggleVideoOpened = () => {
		if (open && isFull) setFull(false);
		setOpen(!open);
	};

	const onended = () => {
		setFull(false);
		// onTogglePauseVideo();
		if (open && isFull) onNextTransition();
		videoRef.current?.play();
	};

	useEffect(() => {
		if (videoRef?.current && isRepeat) {
			videoRef.current.currentTime = 0;
			videoRef.current.play();
		}
	}, [isRepeat, videoRef]);

	if (!currentExercise) return <></>;

	return (
		<Content
			className="md:w-[240px] lg:w-[300px] xl:w-[360px] 2xl:w-[420px]"
			style={{
				position: 'absolute',
				width: isSwitchMode || isFull ? '100%' : '',
				zIndex: isSwitchMode ? 3 : 4,
				backgroundColor: open ? '#2c1c4e' : 'transparent',
				aspectRatio: '4/2',
				opacity: 0.85,
				height: isFullscreen && (isSwitchMode || isFull) ? "100vh" : 'auto'
			}}>
			{isFull && !showVideo && (
				<div
					style={{
						backgroundColor: 'rgba(105, 65, 198, 0.85)',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						opacity: 0.85,
						overflow: 'hidden',
					}}>
					<TextAnimation
						text={
							currentExercise?.name?.toUpperCase() ||
							currentExercise?.title?.toUpperCase()
						}
						bodySideTitle={currentExercise?.bodySideTitle?.toUpperCase()}
						movementTitle={currentExercise?.movementTitle?.toUpperCase()}
					/>
				</div>
			)}

			{showVideo && (
				<video
					style={{
						visibility: open || isSwitchMode ? 'visible' : 'hidden',
						width: '100%',
						aspectRatio: '4/2',
						height: 'auto',
						objectFit: 'cover'
					}}
					id="instructionalvideo"
					ref={videoRef}
					autoPlay
					muted
					loop={!isFull}
					preload="metadata"
					key={currentExercise?.id}
					src={
						currentExercise?.video?.url
							? currentExercise?.video?.url
							: currentExercise?.video
					}
					onEnded={onended}
				/>
			)}
			{!isSwitchMode &&
				(open ? (
					<PiArrowUpLeftBold
						size={40}
						style={{
							color: '#fff',
							position: 'absolute',
							right: 0,
							bottom: isFullscreen && isFull ? 35 :0,
							backgroundColor: '#5E59C1',
							cursor: 'pointer',
						}}
						onClick={onToggleVideoOpened}
					/>
				) : (
					<PiArrowDownRightBold
						size={40}
						style={{
							color: '#fff',
							position: 'absolute',
							left: 0,
							top: 0,
							backgroundColor: '#5E59C1',
							cursor: 'pointer',
						}}
						onClick={onToggleVideoOpened}
					/>
				))}
		</Content>
	);
}

export default InstructionalVideo;
