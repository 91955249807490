import { useTranslation } from 'react-i18next';
import { LogOut04 } from '@carespace-icons/general/logOut04';
import { Phone02 } from '@carespace-icons/media/phone02';
import { HomeLine } from '@carespace-icons/general/homeLine';
import { List } from '@carespace-icons/layoult/list';
import { PlaySquare } from '@carespace-icons/media/playSquare';
import ThemeSelector from '@pages/Themes/ThemeSelector';
import { MdOutlineAssignment } from 'react-icons/md';
import { Image01 } from '@carespace-icons/files/image01';
import { PlusSquare } from '@carespace-icons/general/plusSquare';
import { MagicWand01 } from '@carespace-icons/editor/magicWand01';
import { BarChartSquarePlus } from '@carespace-icons/charts/barChartSquarePlus';
import { BarChart01 } from '@carespace-icons/charts/barChart01';
import { FileShield02 } from '@carespace-icons/security/fileShield02';
import { Heart } from '@carespace-icons/general/heart';
import { useTypedSelector } from '@stores/index';

export const useMenuConstants = () => {
    const { t } = useTranslation();
    const getThemeDetails = useTypedSelector(
        state => state.settings.settings.getTheme
    );
    
    const mainMenu = [
        {
            label: t('Admin.data.menu.home.home'),
            key: '',
            icon: <HomeLine width={20} height={20} color={'stroke-white'} />,
        },
        {
            label: t('Tools'),
            key: 'tools',
            icon: <MagicWand01 width={20} height={20} color={'stroke-white'} />,
        },
    ];

    const menuUser = [
        {
            label: 'Activity',
            key: 'activity',
            icon: <Heart width={20} height={20} color={'stroke-primary-700'} />,
        },
        {
            key: 'virtual-evaluation',
            icon: <FileShield02 width={20} height={20} color={'stroke-primary-700'} />,
            label: t('Evaluation'),
            options: [
                {
                    key: 'virtualEvaluation',
                    icon: <MdOutlineAssignment className="text-primary-700" height={16.67} width={13.33} />,
                    name: 'Start Virtual Evaluation',
                },
                {
                    key: 'listEvaluation',
                    icon: <List color="stroke-primary-700" height={15} width={15} />,
                    name: 'Evaluation Results',
                },
            ],
        },
        {
            key: 'omni-rom',
            icon: <PlaySquare width={20} height={20} color={'stroke-primary-700'} />,
            label: t('OmniROM'),
            options: [
                { key: 'startScan', icon: <PlaySquare color="stroke-primary-700" height={16.67} width={15} />, name: 'Start Scan' },
                { key: 'romSummary', icon: <List color="stroke-primary-700" height={15} width={15} />, name: 'OmniROM Summary' },
                { key: 'captures', icon: <Image01 color="stroke-primary-700" height={15} width={15} />, name: 'OmniROM Captures' },
                { key: 'postureSummary', icon: <List color="stroke-primary-700" height={15} width={15} />, name: 'Posture Summary' },
                { key: 'postureCaptures', icon: <Image01 color="stroke-primary-700" height={15} width={15} />, name: 'Posture Captures' },
            ],
        },
        {
            key: 'lets-move',
            icon: <PlusSquare width={20} height={20} color={'stroke-primary-700'} />,
            label: t("Let's Move"),
            options: [
                { key: 'programs', icon: <PlusSquare color="stroke-primary-700" height={15} width={15} />, name: 'Program List' },
                { key: 'listSessions', icon: <List color="stroke-primary-700" height={15} width={15} />, name: 'Program Summary' },
            ],
        },
        {
            key: 'survey',
            icon: <List width={20} height={20} color={'stroke-primary-700'} />,
            label: t('Survey'),
            options: [
                { key: 'startSurveyUser', icon: <MdOutlineAssignment className="text-primary-700" height={16.67} width={13.33} />, name: 'Start Survey' },
                { key: 'surveySummary', icon: <List color="stroke-primary-700" height={15} width={15} />, name: 'Survey Summary' },
            ],
        },
        {
            key: 'reports',
            icon: <BarChart01 width={20} height={20} color={'stroke-primary-700'} />,
            label: t('Reports'),
            options: [
                { key: 'createReport', icon: <BarChartSquarePlus color="stroke-primary-700" height={15.83} width={15.83} />, name: 'Create Reports' },
                { key: 'myReport', icon: <BarChart01 color="stroke-primary-700" height={15} width={15} />, name: 'Report Summary' },
            ],
        },
    ];

    const avatarMenus = [
        ...(getThemeDetails.locked ? [] : [
            {
                label: t('Apply Theme'),
                key: 'theme-selector',
                icon: <ThemeSelector />
            }
        ]),
        {
            label: t('Admin.data.menu.home.downloadAppView'),
            key: 'downloadApp',
            icon: <Phone02 width={20} height={20} color={'stroke-white'} />,
        },
        {
            label: t('Logout'),
            key: 'logout',
            icon: <LogOut04 width={20} height={20} color={'stroke-white'} />,
        },
    ];

    return { mainMenu, menuUser, avatarMenus };
};
